import { lazy, Suspense, useEffect, useState } from "react";
import "./homepage.css";
import Proud from "./proud/Proud";
import Sponsor from "./sponsor/Sponsor";
import MobileSponsors from "./sponsor/MobileSponsor";
import useUtmParams from "../../utils/useUtmParams";
import NewSeason from "../newSeason/newseason";

const HomePage = () => {
  useUtmParams();
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    console.log("hello");
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);


  return (
    <div className="homepage-container">
      <NewSeason/>
        <Proud />
        {isMobile ? <MobileSponsors /> : <Sponsor />}
    </div>
  );
};

export default HomePage;
