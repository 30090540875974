import React, { useRef, useState, lazy, Suspense } from "react";
import "./newseason.css";

// Lazy load components
const HomeBannerr = lazy(() => import("./homebannerr"));
const MovingBanner = lazy(() => import("./movingbanner"));
const SlayerCards = lazy(() => import("./slayercards"));
const Tribe = lazy(() => import("./tribe"));
const FindRaceCard = lazy(() => import("./findracecard"));
const GetCard = lazy(() => import("./getcard"));
const Warrior = lazy(() => import("./warrior"));
import LogoLoader from "../homepage/loder/LogoLoader"; 
const NewSeason = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <Suspense fallback={<LogoLoader />}>

    <div className="newseason-container">
      {/* Video Section */}
      <div className="video-container" style={{ position: "relative" }}>
        <video
          className="page-video-tag"
          ref={videoRef}
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          playsInline
          muted={isMuted}
        >
          <source
            src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/compress+website+video.mp4"
            type="video/mp4"
          />
        </video>
        <button
          className="mute-button"
          onClick={handleMuteToggle}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            padding: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            color: "white",
          }}
        >
          {isMuted ? "🔇" : "🔊"}
        </button>
      </div>

      <Suspense fallback={<LogoLoader />}>
        <MovingBanner />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <HomeBannerr />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <FindRaceCard />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <GetCard />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <Warrior />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <Tribe />
      </Suspense>

      <Suspense fallback={<LogoLoader />}>
        <SlayerCards />
      </Suspense>
    </div>
    </Suspense>

  );
};

export default NewSeason;
