import webpImage from "../../assets/medical.webp";
import food from "../../assets/village/food.webp";

export const villagetext = {
  villagehead: "Embrace the Village Spirit",
  villageubtext1:
    "Unlike other running events, our participants don’t leave after crossing the finish line. The reason for this is the party that happens at the Village!",
  villageubtext2:
    "The Maruti Suzuki Arena Devils Circuit Village is where participants, spectators, and partners all come together to have one awesome party!",
  parallaxdata: [
    {
      id: 1,
      head: "Concert",
      text: "The massive stage is the focal point of each event, where our entertainment partners kick things off with energetic warm-ups and lively dance sessions. Imagine the excitement and energy buzzing in the air as you groove to the beats, feeling the thrill of the moment. The hub of entertainment ensures that every participant is pumped up and ready to tackle the course.",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/concert.webp",
    },
    {
      id: 2,
      head: "Music",
      text: "A DJ sets the atmosphere as you step into the village, making you bust moves you didn’t know you had. Groovy, smooth, and electrifying, their beats pump up the crowd and turn the energy all the way up!",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/jpeg-optimizer_Rectangle%2B314.webp",
    },
    {
      id: 3,
      head: "Food",
      text: "You will not go thirsty or hungry on the grounds, as we have fresh drinks and delicious food ready to be enjoyed by participants and their loved ones. From refreshing beverages to satisfying snacks, we've got everything you need to keep your energy up and your spirits high!",
      img: food,
    },
    {
      id: 4,
      head: "Engagement",
      text: "You won't have any opportunity to get bored on the grounds of Devils Circuit. This is because our partners create bespoke zones to not only entertain but also reward you. So, you have the chance to win exciting prizes, adding to the experience of a lifetime. ",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/770600-2-668d226e4ec40.webp",
    },
    {
      id: 5,
      head: "Medical",
      text: "We know you want to show off your battle bruises, but our medical team is always on standby for every scrape, graze, and your overall safety. Rest assured, you’re in good hands!",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/jpeg-optimizer_medical.webp",
    },
    {
      id: 6,
      head: "Photos",
      text: "What’s an event without capturing unforgettable moments? At the Maruti Suzuki Arena Devils Circuit, photo ops are everywhere! Whether you’re conquering obstacles, celebrating with your team, or soaking in the atmosphere, there’s always a perfect moment to capture. Strike a pose, flash a smile, and let the memories roll!",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/photos.webp",
    },
  ],
};
