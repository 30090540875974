import React from "react";
import "./logoloader.css";
import logo from "../../../assets/logoloder.svg"; 
const LogoLoader = () => {
  return (
    <div className="logo-loader-container">
      <img src={logo} alt="Loading..." className="logo-loader" />
    </div>
  );
};

export default LogoLoader;