import { data } from "../../../src/assets/cateories/data";
import percent from "../../assets/sroke.png";
import "./whatsIncluded.css";
import PageHead from "../common/pageHead/pageHead";
import FindRace from "../common/findRaceFooter/findrace";
function WhatsIncluded() {
  console.log(data.included[1]);
  return (
    <>
      <div>
        <PageHead
          page_name={data.included[0].heading}
          text_1={data.included[0].included}
          page_head={data.included[0].subheading}
          media={data.included[0].image_url}
          media_type="image"
        />
        <div className="black">
          <div className="imageContainer">
            <img src={data.included[1].image1} alt="tshirt" />
          </div>
          <div>
            <h1>{data.included[1].text1}</h1>
            <p>{data.included[1].para1}</p>
          </div>
        </div>
        <div className="white">
          <div className="imageContainer">
            <img src={data.included[1].image2} alt="medal" />
          </div>
          <div>
            <h1>{data.included[1].text2}</h1>
            <p>{data.included[1].para2}</p>
          </div>
        </div>
        <div className="black">
          <div className="imageContainer">
            <img src={data.included[1].image3} alt="bisleri" />
          </div>
          <div>
            <h1>{data.included[1].text3}</h1>
            <p>{data.included[1].para3}</p>
          </div>
        </div>
        <div className="white">
          <div className="imageContainer">
            {" "}
            <img src={data.included[1].image4} alt="drink" />
          </div>
          <div>
            <h1>{data.included[1].text4}</h1>
            <p>{data.included[1].para4}</p>
          </div>
        </div>
        <div className="black">
          <div className="imageContainer">
            {" "}
            <img src={data.included[1].image5} alt="bandana" />
          </div>
          <div>
            <h1>{data.included[1].text5}</h1>
            <p>{data.included[1].para5}</p>
          </div>
        </div>
        <div className="white">
          <div className="imageContainer">
            <img src={data.included[1].image6} alt="goody" />
          </div>
          <div>
            <h1>{data.included[1].text6}</h1>
            <p>{data.included[1].para6}</p>
          </div>
        </div>
        <div className="black">
          <div className="imageContainer">
            <img src={percent} alt="percent" />
          </div>
          <div>
            <h1>{data.included[1].text7}</h1>
            <p>{data.included[1].para7}</p>
          </div>
        </div>
        <div className="white">
          <div className="imageContainer">
            <img src={data.included[1].image7} alt="photos" />
          </div>
          <div>
            <h1>{data.included[1].text8}</h1>
            <p>{data.included[1].para8}</p>
          </div>
        </div>
      </div>
      <FindRace
        heading={data.included[2].heading}
        para={data.included[2].para1}
        btn_link={"/find-your-race"}
        btn_text={data.included[2].button}
      />
      {/* </div> */}
    </>
  );
}
export default WhatsIncluded;
