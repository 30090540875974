import React, { useState, useEffect } from "react";
import "./findYourRace.css";
import FindYourRaceCard from "./FindYourRaceCard";
import { Link } from "react-router-dom";
import PageHead from "../common/pageHead/pageHead";
import FindRace from "../common/findRaceFooter/findrace";
import FindPastYourRaceCard from "./FIndYourPastRaceCard";
import useUtmParams from "../../utils/useUtmParams";

const data = [

  {
    id: 1,
    city: "New Chandigarh",
    date: "February 16, 2025 | Sunday",
    link:"https://getmybib.com/158/register",
    knowMoreLink: "city-page/Chandigarh",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(2)_11zon.webp",
  },
  {
    id: 2,
    city: "Delhi NCR",
    date: "March 9, 2025 | Sunday",
    link:"https://getmybib.com/157/register",
    knowMoreLink: "city-page/Delhi",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(1)_11zon.webp",
  },
];

const pastdata = [
  {
    id: 1,
    city: "Indore",
    date: "September  29, 2024 | Sunday",
    link:"https://getmybib.com/165/register",
    knowMoreLink: "city-page/Indore",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/273X229+(9)_11zon.webp",
  },
  {
    id: 2,
    city: "Mumbai",
    date: "October 20, 2024 | Sunday",
    link:"https://getmybib.com/163/register",
    knowMoreLink: "city-page/Mumbai",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229_11zon.webp",
  },
  {
    id: 3,
    city: "Ahmedabad",
    date: "November 10, 2024 | Sunday",
    link:"https://getmybib.com/166/register",
    knowMoreLink: "city-page/Ahmedabad",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(8)_11zon.webp",
  },
  {
    id: 4,
    city: "Pune",
    date: "November 24, 2024 | Sunday",
    link:"https://getmybib.com/164/register",
    knowMoreLink: "city-page/Pune",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(7)_11zon.webp",
  },
  {
    id: 5,
    city: "Bengaluru",
    date: "December 15, 2024 | Sunday",
    link:"https://getmybib.com/161/register",
    knowMoreLink: "city-page/Bengaluru",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(6)_11zon.webp",
  },
  {
    id: 6,
    city: "Hyderabad",
    date: "January 5, 2025 | Sunday",
    link:"https://getmybib.com/162/register",
    knowMoreLink: "city-page/Hyderabad",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(5)_11zon.webp",
  },
  {
    id: 7,
    city: "Kochi",
    date: "January 19, 2025 | Sunday",
    link:"https://getmybib.com/160/register",
    knowMoreLink: "city-page/Kochi",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(4)_11zon.webp",
  },
  {
    id: 8,
    city: "Chennai",
    date: "February 2, 2025 | Sunday",
    link:"https://getmybib.com/159/register",
    knowMoreLink: "city-page/Chennai",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/273X229+(3)_11zon.webp",
  },
];
const FindYourRace = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  useUtmParams();
  var aspectRatio = 1443 / 600;
  var cardHeight = screenWidth / aspectRatio;

  const actualSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualSize);

    return () => {
      window.removeEventListener("resize", actualSize);
    };
  }, []);

  const para =
    "Gear up with a branded Jockey t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious.";

  return (
    <div className="findyourrace_wrapper">
      <div className="findyourrace_main_div">
        <PageHead
          page_name={"FIND YOUR RACE"}
          page_head={"Conquer Challenges:"}
          text_1={"Find your ultimate Obstacle Course Race!"}
          text_2={
            "Embark on an epic journey and conquer every obstacle in the ultimate test of grit, endurance, and victory!"
          }
          media={
            "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/Find+your+race_11zon.webp"
          }
          media_type={"image"}
        />

        <div className="findyourrace_cards_div">
          <div className="findyourrace_cards_header_div">
            <div className="findyourrace_cards_header_inner_div">
              <p className="findyourrace_cards_header">Find Your Race</p>
            </div>
          </div>

          <div className="findyourrace_cards_main_div">
            <div className="findyourrace_cards_inner_div">
              <FindYourRaceCard data={data} />
            </div>
          </div>
        </div>
        <div className="findyourrace_cards_div">
          <div className="findyourrace_cards_header_div">
            <div className="findyourrace_cards_header_inner_div">
              <p className="findyourrace_cards_header">Past Races</p>
            </div>
          </div>

          <div className="findyourrace_cards_main_div">
            <div className="findyourrace_cards_inner_div">
              <FindPastYourRaceCard data={pastdata} />
            </div>
          </div>
        </div>
        <div className="dividerband">Be a Devilslayer</div>
        <div className="findyourracebanner">
          <img
            src={
              "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/Be+a+devilslayers+(1).webp"
            }
          />
        </div>
        <FindRace
          heading={"What's Included"}
          para={para}
          btn_link={"/whatsIncluded"}
          btn_text={"Know More"}
          imageUrl="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/Whats%2Bincluded+(2).webp
"
        />
      </div>
    </div>
  );
};

export default FindYourRace;
