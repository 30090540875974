import React, { useState } from "react";
import PageHead from "../common/pageHead/pageHead";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import Banner from "../categories/banner";
import MovingBanner from "../common/movingBanner/movingBanner";
import FindRace from "../common/findRaceFooter/findrace";
import "../categories/corporate.css";
import axios from "axios";
// import "./corporate.css";
const data = {
  marketing: [
    {
      heading: "Corporate Participation",
      marketing:
        "So whether you have already experienced the magic of Maruti Suzuki Arena Devils Circuit, or you are a newbie, reach out to us and we will help you register your colleagues. Over 1000 corporate organisations have had their employees join the tribe of devilslayers in the last decade, here is why! ",
      // image_url: "path/to/your/image.jpg", // Uncomment if needed
    },
  ],
};

const CorporatePage = () => {
  const [spinState, setSpinState] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Name should be atleast 2 characters")
      .max(100, "Name should be less 100 characters")
      .required("Name is required"),
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    Mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
      .required("Mobile Number is required"),
    Company: Yup.string()
      .min(2, "Company should be atleast 2 characters")
      .max(100, "Company should be less 100 characters"),
    // .required("Company is required"),
    Position: Yup.string()
      .min(2, "Position should be atleast 2 characters")
      .max(100, "Position should be less 100 characters"),
    // .required("Position is required"),
    Address: Yup.string()
      .min(2, "Address should be atleast 2 characters")
      .max(100, "Address should be less 100 characters"),
    // .required("Address is required"),
    Message: Yup.string()
      .min(2, "Message should be atleast 2 characters")
      .max(500, "Message should be less 500 characters"),
    // .required("Message is required"),
  });

  const initialValues = {
    fullName: "",
    Email: "",
    Mobile: "",
    Company: "",
    Position: "",
    Address: "",
    Message: "",
  };

  const handleSubmit = async (values) => {
    setSpinState(true);

    try {
      await axios.post("https://submit-form.com/s0c4CR8A5", values);
      setSpinState(false);
      toast.success("Message submited successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const preventArrowKeyIncrement = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  return (
    <>
      <PageHead
        page_name={data.marketing[0].heading}
        // text_1={data.marketing[0].marketing}
      />
      <div className="secondHeadingCorporate">
        Be The Team Everyone Talks About!
      </div>
      {/* <FindRace
        heading="Corporate Participation"
        para="Unlock an exclusive team-building experience for your organization with Maruti Suzuki Arena Devils Circuit. Elevate your corporate culture through fun, fitness, and fierce competition."
        btn_link={"/corporate-us"}
        btn_text="corporate Us"
      /> */}

      <div className="whyjoin">Why Join the Tribe of Devilslayers?</div>
      <div className="bannerDesc">
        <ul className="unorderdListCorporate">
          <li>Bond with colleagues through the ultimate challenge</li>
          <li>All Levels Welcome: from seasoned pros to newbies</li>
          <li>1000+ Corporate Teams have conquered the course!</li>
        </ul>
      </div>
      <Banner />
      <div className="corporateContainer">
        <h1>Add-ons You Can Purchase</h1>
        <div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/tshirt+(2)_11zon.jpg"
              alt="shirt"
            />
            <p>T-shirt Branding</p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>

          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/corporate+(3)+copy.webp"
              alt="corporate"
            />
            <p>Corporate Banner</p>
            <div>
              ₹ 2000 <span> /corporate</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Rectangle+419+(1).png"
              alt="shirt"
            />
            <p>Corporate Trophy </p>
            <div>
              ₹ 3000 <span> /corporate</span>
            </div>{" "}
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/management.png"
              alt="shirt"
            />
            <p>Flag Off by Management</p>
            <div>
              Above 200 &nbsp;<span>participants</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/exclusive+(2)+copy.webp"
              alt="shirt"
            />
            <p>Exclusive Wave</p>
            <div>
              Above 350 &nbsp;<span>participants</span>
            </div>
          </div>

          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/Rectangle+419+(2).png"
              alt="shirt"
            />
            <p>Logo on BIB </p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Rectangle+419+(2).png"
              alt="shirt"
            />
            <p>Digital Certificates to All</p>
            <div>
              ₹ 5000<span> /corporate</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/mealCoupon.png"
              alt="shirt"
            />
            <p>Meal Coupon</p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>
        </div>
      </div>
      <div className="corporate_form_main_div">
        <div className="corporate_form_div">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="corporate_form">
                  <div className="corporate_field_box">
                    <label htmlFor="fullName">Name*</label>
                    <Field
                      type="text"
                      id="fullName"
                      name="fullName"
                      className="corporate_field"
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_field_box">
                    <label htmlFor="Email">Email*</label>
                    <Field
                      type="email"
                      id="Email"
                      name="Email"
                      className="corporate_field"
                    />
                    <ErrorMessage
                      name="Email"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_field_box">
                    <label htmlFor="Mobile">Mobile*</label>
                    <Field
                      type="number"
                      id="Mobile"
                      name="Mobile"
                      className="corporate_field"
                      onKeyDown={preventArrowKeyIncrement}
                    />
                    <ErrorMessage
                      name="Mobile"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_field_box">
                    <label htmlFor="Company">Company</label>
                    <Field
                      type="text"
                      id="Company"
                      name="Company"
                      className="corporate_field"
                    />
                    <ErrorMessage
                      name="Company"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_field_box">
                    <label htmlFor="Position">Position</label>
                    <Field
                      type="text"
                      id="Position"
                      name="Position"
                      className="corporate_field"
                    />
                    <ErrorMessage
                      name="Position"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_field_box">
                    <label htmlFor="Address">City</label>
                    <Field
                      type="text"
                      id="Address"
                      name="Address"
                      className="corporate_field"
                    />
                    <ErrorMessage
                      name="Address"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>

                  <div className="corporate_text_area">
                    <label htmlFor="Message">Leave us a message</label>
                    <Field
                      as="textarea"
                      type="text"
                      id="Message"
                      name="Message"
                      className="corporate_text_area_field"
                    />
                    <ErrorMessage
                      name="Message"
                      component="div"
                      className="corporate_invalid_feedback"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="corporate_join_btn_div"
                  style={{
                    cursor: !spinState ? "pointer" : "none",
                  }}
                >
                  <div className="corporate_join_btn_inner_div">
                    {spinState ? (
                      <CircularProgress
                        className="custom-progress"
                        style={{ color: "white" }}
                        size={25}
                        thickness={3}
                      />
                    ) : (
                      <span>Submit</span>
                    )}
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <MovingBanner showBorder={false} />
      <FindRace
        heading="What's Included!"
        para="Gear up with a branded t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious."
        btn_link={"/whatsIncluded"}
        btn_text="Know More"
        imageUrl="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/Whats%2Bincluded+(2).webp"
      />
            <ToastContainer />
    </>
  );
};

export default CorporatePage;
