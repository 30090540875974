export const data = {
  marketing: [
    {
      heading: "MARKETING ASSOCIATION",
      marketing:
        "Our partnerships are long-lasting because we recognize that the trust you place in us must be repaid with ROI that exceeds your expectations. So, if you are an agency, a marketer, or an entrepreneur passionate about your brand, get in touch—we’re eagerly waiting to show you what we can do for you.",
      para1:
        "Let's collaborate to create a dynamic marketing plan that not only meets but exceeds your expectations, driving sustained growth and long-term success for your brand.",
      para2: "Reach out to us at",
      para3: "and let us get talking!",
      email: "marketing@devilscircuit.com",
      image_url:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/marketingB.svg",
    },
  ],
  venue: [
    {
      heading: "VENUE PARTNERSHIP",
      venue:
        "At the Maruti Suzuki Arena Devils Circuit, each edition is curated with the same mindset-  giving each participant  memories of a lifetime! Our city editions attract anywhere from 3000 to 10,000+ participants per day and we tie up with owners and real estate developers to host these races on their land banks.",
      image_url:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Rectangle%2B421%2B(2)_11zon.webp",
    },
    {
      subheading: [
        {
          heading: "What's in it for you?.",
          text1:
            "The ability to host your potential target audience on your property.",
          text2: "Showcase your development, show homes, and infrastructure.",
          text3:
            "Integrate your brand across all our city specific marketing initiatives.",
          text4: "Space in the village area to create engagement.",
          text5:
            "So if you have more than 15 acres of contiguous land and want to host the Maruti Suzuki Arena Devils Circuit, reach out to us at.",
          text6: "and let's get talking!.",
          email: " venue@devilscircuit.com ",
        },
      ],
    },
  ],
  gym: [
    {
      heading: "GYM PARTNERSHIP",
      gym: "Our participants ask us all the time where they can train for the Maruti Suzuki Arena Devils Circuit! And we would love to point them to you.",
      image_url:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/Gym_11zon.webp",
    },
    {
      subheading: [
        {
          heading: "What you can expect from us? ",
          text1: "Training details",
          text2: "Marketing collateral",
          text3: "Special members pricing ",
          text4: "Promotion to our community",
          text5: "and a lot more...",
          text6:
            "So if you run a gym and want to discover what a partnership with the Maruti Suzuki Arena Devils Circuit entails, reach out to us at ",
          email: "gympartner@devilscircuit.com",
          text7: "and lets get talking!",
        },
      ],
    },
  ],
  included: [
    {
      heading: "WHAT'S INCLUDED",
      subheading: "Here's What You Get",
      included:
        "When you purchase a ticket to the Maruti Suzuki Arena Devils Circuit Race, you get a lot more than a chance to participate!",
      image_url:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/what's+included_11zon.webp",
    },
    {
      image1:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/GymImages/jocket+tshirt(1)+(1).webp",
      image2:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/723A0381+3+(1).png",
      image3:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/Daco_4589932-removebg-preview+2+(1).png",
      image4:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/whatsincluded.webp",
      image5:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Wanderlust_2_e2ab6367-189d-4248-9c46-63d9a42f9501%2B4+(1).webp",
      image6:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/bag-66894bee7c6c7.webp",
      image7:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Photo_Frame_No_Photo_2%2B2%2B(1)_11zon.jpg",
      text1: "Jockey Move T-shirt",
      text2: "Medal",
      text3: "Hydration",
      text4: "Energy Drink",
      text5: "Iconic Bandana",
      text6: "Goody Bag",
      text7: "Savings",
      text8: "Photographs",
      para1:
        "A cool new Jockey t-shirt is here for you! Jockey’s StayDry and StayFresh Tech Tee will make every move of yours super comfortable on the event day.",
      para2:
        "A finisher medal awaits you as you cross the finish line. Not to forget the bragging rights you earn to show off how cool your Sunday was!",
      para3:
        "We know it’s only 5 km, but hey, we’ve got you covered with hydration points placed at every kilometer along the circuit.",
      para4:
        "You want to celebrate after finishing your race. Well, what better way than with an energy drink to help you beat the exhaustion?",
      para5:
        "The red and black bandana can only be earned, never bought! It is your way of letting the world know that you are a devilslayer.",
      para6:
        "The mystery goody bag changes every year and so do the giveaways. You should wonder what you’ll find in yours this season!",
      para7:
        "And yes, if you purchase your spot in advance you can get upto 20% early bird price reduction, so get started now.",
      para8:
        "A team of talented photographers are constantly on the move trying to capture your best moments. Smile, pose, and download your best photos post-race.",
    },
    {
      heading: "Unleashing the Power of Resistance!",
      para1:
        "Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance.",

      button: "Find Your Race",
    },
  ],
};
