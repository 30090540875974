import React, { useEffect, useState } from "react";
import "./instafeed.css";
import { Link } from "react-router-dom";
import axios from "axios";
import InstagramIcon from '@mui/icons-material/Instagram';

const InstaFeed = () => {
  const [mediaData, setMediaData] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const speed = 50000;

  const fetchData = async () => {
    try {
      const response = await axios.get("https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,media_type,permalink&access_token=IGQWRNVTVqLUJOT2I0RnRlRDRjNmpveUc4SlR3ZAS1sUEpiX196SHpGbTRNaTlURVUxZA0ZAKXzdmTnR5UTE3aXBVdzJidWo2TElwT0NUUmZAnSENSVmFtQ3JXMm8waVdZAQ2RGMGtQQjV6eWtUMjRjaFRaZAmFCTVQ1UTAZD");

      const filteredData = response.data.data.filter(
        (item) => item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM"
      );

      setMediaData((prevData) => [...prevData, ...filteredData]);

      if (response.data.paging && response.data.paging.next) {
        console.log("next page");
        setNextPage(response.data.paging.next);
      } else {
        setNextPage(null);
      }
    } catch (error) {
      console.error("Error fetching Instagram data:", error);
    }
  };

  useEffect(() => {
    fetchData();

  }, []);

  useEffect(() => {
    console.log(mediaData.length, mediaData);
    if (mediaData.length < 10) {
      console.log(mediaData.length);
      fetchData(nextPage);
    }
  }, [mediaData, nextPage]);

  const handleHover = (e) => {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      section.style.animationPlayState = "paused";
    });
  };

  const handleMouseLeave = (e) => {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      section.style.animationPlayState = "running";
    });
  };

  const handleIconClick = () => {
    window.location.href = 'https://www.instagram.com/devilscircuit/';
  };

  return (
    <div className="outerMostDiv">
      <p className="instaHeading">#Devils Circuit</p>
      <div className="instaSubHeader">
        <p className="instaSubHeading">Join the movement</p>
        <InstagramIcon className="instaIcon" onClick={handleIconClick} />
      </div>

      <div className="OuterInstaDiv">
        <div className="InnerInstaDiv">
          <section style={{ "--speed": `${speed}ms` }}>
            {mediaData.map(
              (item) => (
                <Link
                  to={item.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                >
                  <div
                    className="instaImage"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={item.media_url} alt={`img_${item.id}`} />
                    {item.caption && <p>{item.caption}</p>}
                  </div>
                </Link>
              )
            )}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            {mediaData.map(
              (item) => (
                <Link
                  to={item.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                >
                  <div
                    className="instaImage"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={item.media_url} alt={`img_${item.id}`} />
                    {item.caption && <p>{item.caption}</p>}
                  </div>
                </Link>
              )
            )}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            {mediaData.map(
              (item) => (
                <Link
                  to={item.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                >
                  <div
                    className="instaImage"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={item.media_url} alt={`img_${item.id}`} />
                    {item.caption && <p>{item.caption}</p>}
                  </div>
                </Link>
              )
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default InstaFeed;
