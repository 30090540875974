// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
    width: 100%;
  }
  
  .logo-loader {
    width: 50px; /* Adjust size as needed */
    height: 50px;
    animation: spin 1s linear infinite; /* Spinning animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/homepage/loder/logoloader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,4BAA4B;IAC3C,WAAW;EACb;;EAEA;IACE,WAAW,EAAE,0BAA0B;IACvC,YAAY;IACZ,kCAAkC,EAAE,uBAAuB;EAC7D;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":[".logo-loader-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100px; /* Adjust height as needed */\n    width: 100%;\n  }\n  \n  .logo-loader {\n    width: 50px; /* Adjust size as needed */\n    height: 50px;\n    animation: spin 1s linear infinite; /* Spinning animation */\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
