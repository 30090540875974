// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nonCompContainer > img {
  width: 100%;
  object-fit: cover;
  height: fit-content;
}
.nonCompContainer > div {
  width: 80%;
  margin: auto;
  padding: 60px 0px;
}
.nonCompContainer > div > p {
  color: #000;
  margin-bottom: 20px;
  font-family: lightThicker !important ;
  font-size: clamp(16px, 4vw, 20px);
  font-weight: 400;
}
.bottom {
  width: 100% !important;
  padding: 50px 0px !important;
  background-color: #ba1e24;
  text-align: center;
}
.bottom > h1 {
  color: #fff;
  font-size: 54px;
  font-weight: 700;
}
.bottom > p {
  color: #fff !important;
  text-align: center;
  font-size: 20px;
  font-family: lightThicker !important;
}
.bottom > button {
  margin-top: 60px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  background: linear-gradient(0deg, #000 0%, #000 100%), #fff;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .nonCompContainer > div {
    width: 90%;
    padding: 20px 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/categories/non_comp.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,qCAAqC;EACrC,iCAAiC;EACjC,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,oCAAoC;AACtC;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,2DAA2D;EAC3D,YAAY;EACZ,eAAe;AACjB;AACA;EACE;IACE,UAAU;IACV,iBAAiB;EACnB;AACF","sourcesContent":[".nonCompContainer > img {\n  width: 100%;\n  object-fit: cover;\n  height: fit-content;\n}\n.nonCompContainer > div {\n  width: 80%;\n  margin: auto;\n  padding: 60px 0px;\n}\n.nonCompContainer > div > p {\n  color: #000;\n  margin-bottom: 20px;\n  font-family: lightThicker !important ;\n  font-size: clamp(16px, 4vw, 20px);\n  font-weight: 400;\n}\n.bottom {\n  width: 100% !important;\n  padding: 50px 0px !important;\n  background-color: #ba1e24;\n  text-align: center;\n}\n.bottom > h1 {\n  color: #fff;\n  font-size: 54px;\n  font-weight: 700;\n}\n.bottom > p {\n  color: #fff !important;\n  text-align: center;\n  font-size: 20px;\n  font-family: lightThicker !important;\n}\n.bottom > button {\n  margin-top: 60px;\n  color: #fff;\n  font-size: 24px;\n  font-weight: 600;\n  padding: 20px;\n  background: linear-gradient(0deg, #000 0%, #000 100%), #fff;\n  border: none;\n  cursor: pointer;\n}\n@media screen and (max-width: 600px) {\n  .nonCompContainer > div {\n    width: 90%;\n    padding: 20px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
